<template>
    <div style="display: flex;justify-content: space-between">
        <div>
            <el-button type="primary" :disabled="loading" @click="openDialog">选图</el-button>
        </div>
<!--        <div>-->
<!--            <el-select v-model="form.region" :disabled="loading" @change="formatData" placeholder="全球" filterable-->
<!--                       clearable>-->
<!--                <el-option v-for="c in region_list" :value="c"-->
<!--                           :label="c"></el-option>-->
<!--            </el-select>-->
<!--        </div>-->
    </div>
    <template v-for="d in data">
        <el-card style="margin-top: 5px" shadow="never" :header="`第${d.day}天`" v-if="d" v-loading="loading">
            <vue-draggable-next v-model="d.images" :animation="300" handle=".handle" @change="resort(d.images)">
                <template v-for="rookie in d.images">
                    <div class="handle" style="display: inline-block;position: relative">
                        <el-image style="margin: 5px;height: 160px"
                                  :src="`https://jigsaw-cdn.plutodesk.com/${rookie.thumbnail}`"></el-image>
                        <el-icon @click="remove(rookie)"
                                 style="position: absolute; top: 5px; right: 5px; cursor: pointer">
                            <CircleClose/>
                        </el-icon>
                        <el-icon v-if="rookie.cost> 0" style="position: absolute; top: 5px;left: 10px;" :size="25" :color="'white'">
                          <VideoCamera />
                        </el-icon>
                    </div>
                </template>
            </vue-draggable-next>
        </el-card>
    </template>
    <el-table style="margin-top: 10px" v-loading="loading" :data="rookie">
      <el-table-column label="预览图" prop="prefix" width="230">
        <template #default="scope">
          <el-image lazy :src="`https://jigsaw-cdn.plutodesk.com/${scope.row.thumbnail}`"
                    style="width: 150px"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="ID" prop="image_id" width="150"></el-table-column>
      <el-table-column label="价格" prop="cost" width="150"></el-table-column>
      <!--      <el-table-column label="价格" prop="cost" width="100">-->
<!--        <template #default="scope">-->
<!--          <el-select v-model="scope.row.cost" style="width: 80px" @change="update(scope.row)">-->
<!--            <el-option :value="0"></el-option>-->
<!--            <el-option :value="20"></el-option>-->
<!--          </el-select>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column label="天数" prop="day" width="180">
        <template #default="scope">
          <el-input-number v-model="scope.row.day" :disabled="loading"  @change="updateRookie(scope.row, $event)"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column label="上线时间" prop="online_date" width="200"></el-table-column>
    </el-table>
    <el-dialog v-model="dialog_opened" :before-close="reset" width="80%">
        <el-form :model="form" label-width="100px" ref="form">
            <el-form-item label="天数" prop="day" :rules="[{required: true, message: '输入天数', type: 'number'}]">
                <el-input-number v-model="form.day" :disabled="loading"></el-input-number>
            </el-form-item>
            <el-form-item label="图片" prop="image_id" :rules="[{required: true, message: '请选择图片'}]">
                <template v-for="image in image_list">
                    <el-image v-if="form.image_id === image.id" style="width: 160px"
                              :src="`https://jigsaw-cdn.plutodesk.com/${image.thumbnail}`"></el-image>
                </template>
            </el-form-item>
        </el-form>
        <el-input v-model="params.id" prefix-icon="Search" @blur="loadImage"></el-input>
        <el-table style="margin-top: 10px" v-loading="loading" :data="image_list">
            <el-table-column label="预览图" prop="prefix" width="230">
                <template #default="scope">
                    <el-image lazy :src="`https://jigsaw-cdn.plutodesk.com/${scope.row.thumbnail}`"
                              style="width: 100px"></el-image>
                </template>
            </el-table-column>
            <el-table-column label="ID" prop="id" width="150"></el-table-column>
            <el-table-column label="分类" prop="categories" width="60">
                <template #default="scope">
                    <template v-for="c in scope.row.categories">
                        <template v-for="cc in category_list">
                            <el-tag size="small" v-if="cc.id===c">{{ cc.i18n_name.EN }}</el-tag>
                        </template>
                    </template>
                </template>
            </el-table-column>
            <el-table-column label="主要标签" prop="major_tags" width="100">
                <template #default="scope">
                    <el-tag v-for="t in scope.row.major_tags">{{ major_map[t] }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="次要标签" prop="minor_tags" width="100">
                <template #default="scope">
                    <el-tag v-for="t in scope.row.minor_tags">{{ minor_map[t] }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="辅助标签" prop="assistant_tags" width="100">
                <template #default="scope">
                    <el-tag v-for="t in scope.row.assistant_tags">{{ assistant_map[t] }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="作者" prop="author" width="100"></el-table-column>
            <el-table-column label="操作" width="100">
                <template #default="scope">
                    <el-button type="primary" @click="addImage(scope.row)" :disabled="loading">添加</el-button>
                </template>
            </el-table-column>
        </el-table>
        <template #footer>
            <el-button type="primary" :loading="loading" @click="reset" text>取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {VueDraggableNext} from 'vue-draggable-next'

export default {
    name: "TranssionRookie",
    components: {VueDraggableNext},
    data() {
        return {
            loading: false, data: [], image_list: [],
            category_list: [], major_list: [], minor_list: [], assistant_list: [],
            category_map: {}, major_map: {}, minor_map: {}, assistant_map: {}, source_list: [],
            dialog_opened: false, rookie: [],
            params: {id: '', status: 'AUDITED', module: 'LIBRARY', transsion_only: false},
            region_list: ['South Africa', 'North Africa', 'Middle East', "Southeast Asia"],
            form: {
                day: 0,
                image_id: '',
                country: '',
                region: '',
            },
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get(`/cms/v1/transsion/rookie`).then(res => {
                this.rookie = res.data.data.imageList;
            }).then(_ => {
                this.loading = false;
                this.formatData();
            })
        },
        openDialog() {
            this.params.id = '';
            this.dialog_opened = true;
        },
        formatData() {
            this.data = [];
            this.rookie.forEach(r => {
                if (r.country === this.form.country || !r.country && !this.form.country) {
                    if (!this.data[r.day]) {
                        this.data[r.day] = {day: r.day, images: []}
                    }
                    this.image_list.forEach(i => {
                        if (i.id === r.image_id) {
                            r.thumbnail = i.thumbnail;
                        }
                    })
                    this.data[r.day].images.push(r);
                }
            });
        },
        reset() {
            this.$refs.form.resetFields();
            this.dialog_opened = this.loading = false;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (!this.form.country) {
                        this.form.country = null;
                    }
                    axios.post(`/cms/v1/transsion/rookie`, this.form).then(res => {
                        this.rookie.push(res.data.data);
                        this.init();
                        this.data.forEach(d => {
                            this.resort(d.images);
                        })
                        this.reset();
                    });
                }
            })
        },
        remove(row) {
            this.loading = true;
            axios.delete(`cms/v1/transsion/rookie/${row.id}`).then(res => {
                this.init()
            });
        },
        addImage(image) {
            this.form.image_id = image.id;
        },
        // update(row) {
          // this.loading = true;
          // axios.put(`/cms/v1/transsion/image/${row.id}`, row).then(res => {
          //   this.loading = false;
          // })
        // },
        updateRookie(rookie, day) {
          this.loading = true
          axios.put(`/cms/v1/transsion/rookie`, {image_id: rookie.image_id, day: day}).then(_ => {
            this.init();
          });
        },
        resort(images) {
            const sort = [];
            images.forEach(i => {
                sort.push(i.id);
            });
            this.loading = true;
            axios.post(`/cms/v1/transsion/sort/rookie`, {sort}).then(res => {
                this.loading = false;
            })
        },
        df(map, list) {
            list.forEach(l => {
                map[l.id] = l.name;
                if (l.children.length) {
                    this.df(map, l.children);
                }
            });
        },
        loadImage() {
            return axios.get(`/cms/v1/transsion/image`, {params: this.params}).then(res => {
                this.image_list = res.data.data.imageList;
            })
        },
    },
    mounted() {
        this.loading = true;
        axios.all([
            axios.get(`/cms/v1/category`).then(res => {
                this.category_list = res.data.data.categoryList.filter(c => !['ALL', 'COLLECTION'].includes(c.key));
                this.category_list.forEach(c => {
                    this.category_map[c.id] = c.i18n_name.ZH_HANS;
                });
            }),
            axios.get('/cms/v1/tag/major').then(res => {
                this.major_list = res.data.data.tagList;
                this.df(this.major_map, this.major_list);
            }),
            axios.get('/cms/v1/tag/minor').then(res => {
                this.minor_list = res.data.data.tagList;
                this.df(this.minor_map, this.minor_list);
            }),
            axios.get('/cms/v1/tag/assistant').then(res => {
                this.assistant_list = res.data.data.tagList;
                this.df(this.assistant_map, this.assistant_list);
            }),
            axios.get('/cms/v1/source').then(res => {
                this.source_list = [];
                res.data.data.sourceList.forEach(s => {
                    if (s && !this.source_list.includes(s)) {
                        this.source_list.push(s);
                    }
                });
            }),
            this.loadImage(),
        ]).then(_ => {
            this.init();
        })
    },
}
</script>

<style scoped>

.el-select {
  width: 200px;
}
</style>