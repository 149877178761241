<template>
    <el-calendar ref="calendar" v-loading="loading">
        <template #date-cell="{data}">
            <div>{{ data.day.split('-').slice(1).join('-') }}</div>
            <div style="display: flex;flex-wrap: wrap" @click="openDialog(data_map[data.day])">
                <img style="margin: 3px;width: 20px" :src="`https://jigsaw-cdn.plutodesk.com/${i.thumbnail}`"
                     v-for="i in data_map[data.day].slice(0, 10)" v-if="data_map[data.day]">
            </div>
        </template>
    </el-calendar>
    <el-table style="margin-top: 20px;" v-loading="loading" :data="dataPage">
        <el-table-column label="ID" prop="id" width="130"></el-table-column>
        <el-table-column label="状态" prop="status" width="100">
            <template #default="scope">
                <el-select :disabled="loading" style="width: 80px" v-model="scope.row.status"
                           @change="update(scope.row)">
                    <el-option label="通过" value="AUDITED"></el-option>
                    <el-option label="审核中" value="AUDITING"></el-option>
                    <el-option label="不通过" value="REJECTED"></el-option>
                </el-select>
            </template>
        </el-table-column>
        <el-table-column label="图片" prop="resource" width="200">
            <template #default="scope">
                <el-image lazy :src="`https://jigsaw-cdn.plutodesk.com/${scope.row.thumbnail}`" style="width: 180px"/>
                <div style="text-align: center;">
                    <a :href="`https://jigsaw-cdn.plutodesk.com/${scope.row.resource}`" target="_blank">高清大图</a>
                </div>
            </template>
        </el-table-column>
        <el-table-column label="价格" prop="cost" width="100">
            <template #default="scope">
                <el-select v-model="scope.row.cost" style="width: 80px" @change="update(scope.row)">
                    <el-option :value="0"></el-option>
                    <el-option :value="20"></el-option>
                </el-select>
            </template>
        </el-table-column>
        <el-table-column label="来源" prop="source" width="100"></el-table-column>
        <el-table-column label="上线时间" prop="online_date" width="140">
            <template #default="scope">
                <el-date-picker style="width: 120px" value-format="YYYY-MM-DD" v-model="scope.row.online_date"
                                @change="update(scope.row)" :clearable="false"></el-date-picker>
            </template>
        </el-table-column>
        <el-table-column label="分类" prop="categories" width="80">
            <template #default="scope">
                <el-tag v-for="c in scope.row.categories">{{ category_map[c] }}</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="主要标签" prop="major_tags" width="100">
            <template #default="scope">
                <el-tag v-for="t in scope.row.major_tags">{{ major_map[t] }}</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="次要标签" prop="minor_tags" width="100">
            <template #default="scope">
                <el-tag v-for="t in scope.row.minor_tags">{{ minor_map[t] }}</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="辅助标签" prop="assistant_tags" width="100">
            <template #default="scope">
                <el-tag v-for="t in scope.row.assistant_tags">{{ assistant_map[t] }}</el-tag>
            </template>
        </el-table-column>
    </el-table>
    <div style="display: flex; justify-content: space-between">
        <el-pagination layout="prev, pager, next" :total="data.length" :page-size="size"
                       v-model:current-page="page" :disabled="loading"></el-pagination>
    </div>
    <el-dialog v-model="dialog_opened" :before-close="reset" width="80%">
        <el-select v-model="country" filterable :disabled="loading" clearable placeholder="全球">
            <el-option v-for="c in $root.country_list" :value="c.code"
                       :label="c.en_name + ' (' + c.code + ')'"></el-option>
        </el-select>
        <vue-draggable-next v-model="imageList" :animation="300" handle=".handle">
            <div v-for="l in imageList" style="margin: 5px;position: relative;display: inline-block"
                 :class="l.include_country && l.include_country.length && !l.include_country.includes(country) || l.exclude_country && l.exclude_country.includes(country)?'':'handle'">

                <el-image :src="`https://jigsaw-cdn.plutodesk.com/${l.thumbnail}`"
                          style="width: 200px;cursor: pointer;"></el-image>
                <div class="cover"
                     v-if="l.include_country && l.include_country.length &&  !l.include_country.includes(country) || l.exclude_country && l.exclude_country.includes(country)
                            || l.platform && l.platform !== platform"></div>
                <el-icon v-if="l.cost> 0" style="position: absolute; top: 0; left: 3px;" :size="25" :color="'white'">
                  <VideoCamera />
                </el-icon>

            </div>
        </vue-draggable-next>
        <template #footer>
            <el-button @click="reset" :loading="loading">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {timestampToDate, update} from "../../libs/utils";
import {VueDraggableNext} from 'vue-draggable-next'

export default {
    name: "TranssionUpdate",
    components: {VueDraggableNext},
    data() {
        return {
            data_map: {}, loading: false, dialog_opened: false, editing: null, list: [], page: 1, size: 20,
            category_list: [], major_list: [], minor_list: [], assistant_list: [], data: [], country: null,
            category_map: {}, major_map: {}, minor_map: {}, assistant_map: {}, platform: 'android',
            sort_type: "transsion", sort_list_map: {}
        }
    },
    methods: {
        init() {
            this.loading = true;
            const now = Date.now();
            axios.get(`/cms/v1/transsion/image`, {
                params: {
                    module: 'LIBRARY', limit: 10000, status: 'AUDITED', transsion_only: false,
                }
            }).then(res => {
                this.data = [];
                res.data.data.imageList.forEach(image => {
                    if (image.online_date) {
                        if (this.data_map[image.online_date]) {
                            this.data_map[image.online_date].push(image);
                        } else {
                            this.data_map[image.online_date] = [image];
                        }
                    }
                    if (!image.online_date || image.online_date > timestampToDate(now)) {
                        this.data.push(image);
                    }
                    // if(image.sorts != null) {
                        // image[this.sort]sort_ios = image.sorts.ios
                    // }
                    // if(image.sort_ios === null || image.sort_ios === undefined) {
                    //   image.sort_ios = image.sort
                    // }
                });
                this.data.sort(function (a, b) {
                    if (a.online_date && b.online_date) {
                        return a.online_date < b.online_date ? 1 : -1;
                    } else if (!a.online_date) {
                        return 1;
                    } else {
                        return -1
                    }
                });
                // for (let key in this.data_map) {
                //     this.data_map[key].sort(function (a, b) {
                //         return a.sort - b.sort;
                //     })
                // }
                this.loading = false;
            });
        },
        openDialog(selected) {
            if (selected) {
                this.dialog_opened = true;
                // this.platform_list_map = {}
                this.sort_list_map = {}
                this.editing = selected;
                update(this.list, selected);
            }
        },
        reload() {
            this.data_map = {};
            this.data.forEach(image => {
                if (image.online_date && image.status === 'AUDITED') {
                    if (this.data_map[image.online_date]) {
                        this.data_map[image.online_date].push(image);
                    } else {
                        this.data_map[image.online_date] = [image];
                    }
                }
            })
        },
        update(row) {
            this.loading = true;
            axios.put(`/cms/v1/transsion/image/${row.id}`, row).then(res => {
                this.reload();
                this.loading = false;
            })
        },
        df(map, list) {
            list.forEach(l => {
                map[l.id] = l.name;
                if (l.children.length) {
                    this.df(map, l.children);
                }
            });
        },
        reset() {
            this.dialog_opened = this.loading = false;
            this.editing = this.country = null;
        },
        submit() {
            this.loading = true;
            const sort = [];
            this.imageList.forEach((d, i) => {
              sort.push(d.id)
              if (d.sorts == null) {
                d.sorts = {}
              }
              d.sorts[this.sort_type] = i
              // if(this.platform === 'ios') {
              //   d.sort_ios = i
              // } else {
              //   d.sort = i
              // }
            });
            axios.post(`/cms/v1/transsion/sort/image`, {sort, sort_type: this.sort_type}).then(_ => {
                update(this.editing, this.list);
                this.reset();
            });
        },
    },
    mounted() {
        this.loading = true;
        axios.all([
            axios.get(`/cms/v1/category`).then(res => {
                this.category_list = res.data.data.categoryList;
                this.category_list.forEach(c => {
                    this.category_map[c.id] = c.i18n_name.ZH_HANS;
                })
            }),
            axios.get('/cms/v1/tag/major').then(res => {
                this.major_list = res.data.data.tagList;
                this.df(this.major_map, this.major_list);
            }),
            axios.get('/cms/v1/tag/minor').then(res => {
                this.minor_list = res.data.data.tagList;
                this.df(this.minor_map, this.minor_list);
            }),
            axios.get('/cms/v1/tag/assistant').then(res => {
                this.assistant_list = res.data.data.tagList;
                this.df(this.assistant_map, this.assistant_list);
            }),
        ]).then(_ => {
            this.init();
        })
    },
    computed: {
        dataPage() {
            return this.data.slice((this.page - 1) * this.size, this.page * this.size);
        },

        imageList: {
          get() {
              if (!this.sort_list_map[this.sort_type]){
                  const list = this.list.filter(
                      item => item.platform === null || item.platform === undefined || item.platform === this.platform);
                      this.sort_list_map[this.sort_type] = list.sort(
                          (a, b) => (
                              (a?.sorts?.[this.sort_type] ?? a.sort ?? 0) - (b?.sorts?.[this.sort_type] ?? b.sort ?? 0)
                      )
                  );
              }
            // if(this.platform === 'ios') {
            //   this.platform_list_map[this.platform] = list.sort((a, b) => (a?.sort_ios ?? 0) - (b?.sort_ios ?? 0));
            // } else {
            //   this.platform_list_map[this.platform] = list.sort((a, b) => (a?.sort ?? 0) - (b?.sort ?? 0));
            // }
            // if (!this.platform_list_map[this.platform]){
            //     const list = this.list.filter(
            //         item => item.platform === null || item.platform === undefined || item.platform === this.platform);
            //     if(this.platform === 'ios') {
            //         this.platform_list_map[this.platform] = list.sort((a, b) => (a?.sort_ios ?? 0) - (b?.sort_ios ?? 0));
            //     } else {
            //         this.platform_list_map[this.platform] = list.sort((a, b) => (a?.sort ?? 0) - (b?.sort ?? 0));
            //     }
            // }
              return this.sort_list_map[this.sort_type]
          },
          set(value) {
              this.sort_list_map[this.sort_type] = value
          }
        }
    }
}
</script>

<style scoped>
.cover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: not-allowed;
    background-color: #FFF;
    opacity: 0.75;
}

.el-select {
  width: 200px;
}

</style>