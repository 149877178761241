<template>
    <el-table :data="data" v-loading="loading" style="margin-top: 10px">
        <el-table-column label="ID" prop="id" width="150"></el-table-column>
        <el-table-column label="主键" prop="key" width="100"></el-table-column>
        <el-table-column label="名称" prop="i18n_name.ZH_HANS" width="100"></el-table-column>
        <el-table-column label="活跃" prop="active" width="100">
            <template #default="scope">
                <el-switch v-model="scope.row.active" :disabled="true" :inactive-value="false"
                           :active-value="true"></el-switch>
            </template>
        </el-table-column>
        <el-table-column label="封面" prop="resource" width="200">
            <template #default="scope">
                <el-image :src="`https://jigsaw-cdn.plutodesk.com/${scope.row.resource}`" style="width: 180px"
                          lazy></el-image>
            </template>
        </el-table-column>
        <el-table-column label="包含国家" prop="include_country" width="100"></el-table-column>
        <el-table-column label="排除国家" prop="exclude_country" width="100"></el-table-column>
        <el-table-column label="操作" width="200">
            <template #default="scope">
                <el-button type="success" circle icon="Top" :loading="loading"
                           :disabled="scope.$index===0" @click="moveUp(scope.$index)"></el-button>
                <el-button type="success" circle icon="Bottom" :loading="loading"
                           :disabled="scope.$index===data.length - 1" @click="moveDown(scope.$index)"></el-button>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
import axios from "ts-axios-new";
import {update} from "../../libs/utils";

export default {
    name: "TranssionCategory",
    data() {
        return {
            loading: false, data: [], dialog_opened: false, editing: null,
            form: {
                active: false,
                key: '',
                i18n_name: {
                    EN: '',
                    FR: '',
                    HI: '',
                    IT: '',
                    ZH_HANS: '',
                    AR: '',
                    ES: '',
                    PT: '',
                    DE: '',
                    EL: '',
                    ZH_HANT: '',
                    ID: '',
                    KO: '',
                    TH: '',
                    NL: '',
                    TR: '',
                    PL: '',
                    VI: '',
                    FI: '',
                    RU: '',
                    HU: '',
                    JA: '',
                    NO: '',
                    SV: '',
                    UK: '',
                    MS: '',
                    IW: '',
                    RO: '',
                    TL: '',
                },
                resource: '',
                include_country: [],
                exclude_country: [],
            },
        }
    },
    computed: {
    },
    methods: {
        handleSuccess(res) {
            this.form.resource = res.data.name
        },
        init() {
            this.loading = true;
            axios.get(`/cms/v1/transsion/category`).then(res => {
                this.data = res.data.data.categoryList;
                this.loading = false;
            });
        },
        reset() {
            this.$refs.form.resetFields();
            this.loading = this.dialog_opened = false;
            this.editing = null;
            this.form.include_country = [];
            this.form.exclude_country = [];
        },
        open(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    this.editing = item;
                    update(this.form, item);
                }
            })
        },
        moveUp(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index - 1, 0, row[0]);
            this.resort();
        },
        moveDown(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index + 1, 0, row[0]);
            this.resort();
        },
        resort() {
            const sort = [];
            this.data.forEach(d => {
                sort.push(d.id);
            })
            axios.post(`/cms/v1/transsion/sort/category`, {sort}).then(_ => {
            })
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>

</style>